$(function () {

  // Get the number of visits from the HTML
  var numVisits = parseInt($('#numVisits').html());

  // Calculate the progress as a percentage of the total needed for level 3
  var progress = Math.min(numVisits / 72 * 100, 100);

  // Update the progress bar
  $('#progress').css('width', progress + '%');

  // Update the level indicator elements
  $('.level-indicator span').removeClass('active');

  if (numVisits < 30) {
    $('#progressText').html('You need at least 30 visits to reach Level I');
    $('.level1').show().addClass('active');
  } else if (numVisits < 72) {
    $('#progressText').html('Level I reached! You need ' + (72 - numVisits) + ' more visits to reach Level II');
    $('.level1').show().addClass('active');
  } else if (numVisits >= 72 && numVisits < 100) {
    $('#progressText').html('Level II reached! You need ' + (100 - numVisits) + ' more visits to reach Level III');
    $('.level2').show().addClass('active');
  } else {
    $('#progressText').html('Congratulations! You have reached Level III');
    $('.level3').show().addClass('active');
  }

    $('#accordion_profile .button_cancel').on('click', function (e) {
        e.preventDefault();
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name=\"csrf-token\"]').attr('content')
            }
        });
        $.ajax({
            url: '/unreserve',
            method: 'post',
            data: {
                courseID: $(this).attr('data-courseID'),
                dayOfWeek: $(this).attr('data-dayOfWeek'),
                courseDate: $(this).attr('data-courseDate'),
                duration: $(this).attr('data-duration'),
                nameID: $(this).attr('data-nameID')
            },
            success: function (result) {
                location.reload();

            },
            error: function (result) {
                console.log(result);
            }
        });
    });

    $("#changeMailForm").on('submit', function (e) {

        e.preventDefault(); // avoid to execute the actual submit of the form.

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name=\"csrf-token\"]').attr('content')
            }
        });

        var form = $(this);

        $.ajax({
            type: "POST",
            url: '/changeMail',
            data: form.serialize(),
            success: function (data) {
                if (data == 1) {
                    $('#modalTitle').html('Erfolg');
                    $('#modalContent').html('Du hast deine Email geändert');
                    $('#modal').modal('show');
                } else {
                    $('#modalTitle').html('Fehler');
                    $('#modalContent').html(
                        `Etwas ist schiefgelaufen. 
                    Bitte versuche es später nochmal erneut oder kontaktiere uns per Email 
                    an info@warungyoga.de
                    <br>
                    Wir helfen dir gerne dabei.`
                    );
                    $('#modal').modal('show');
                }
            }
        });

    })
});