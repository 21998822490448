$(function () {


    $('.btn_courseplan_modal').on('click', () => {
        $('#courseplan_modal').modal('hide');
    })

    $('#button_courseplan').on('click', () => {
        $('#courseplan').toggleClass('courseplan_show');
    })

    // hide/show courseplan
    $('#button-courseplan').on("click", () => {
        $('#courseplan').toggleClass('courseplan_show');
        $('#button-courseplan').css({ 'opacity': '0', 'z-index': '-1' });
    });

    $('#btn_courseplan_close').on('click', () => {
        $('#courseplan').toggleClass('courseplan_show');
        $('#button-courseplan').css({ 'opacity': '1', 'z-index': '2' });
    });

    $('#tab_close').on('click', () => {
        $('#courseplan').removeClass('courseplan_show');
        $('#button-courseplan').css({ 'opacity': '1', 'z-index': '2' });
    });

    // Show course info
    $('.div_course_top').on('click', function () {

        $('.div_course_top').css('width', '100%');
        $('.btn_reserve').css({
            'width': '0px',
            'right': '-33px'
        })

        $(this).css('width', '94%');
        $(this).find('button').css({
            'width': '100px',
            'right': '0'
        })
    })
});