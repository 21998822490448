$(function ()
{

    let analytics = getCookie('analyticsEnabled');
    let cookiesAccepted = getCookie('cookiesAccepted');
    console.log(analytics)
    if (cookiesAccepted == 1)
    {
        $('#cookieBanner').remove();
    }
    if (analytics == 0)
    {
        $('#script_analytics').remove();
    }

    $('#btn_save_cookie').on('click', function ()
    {
        setCookie('cookiesAccepted', 1, 30);
        let $enableAnalytics = $('#cb_analytics').prop('checked');
        if ($enableAnalytics == true)
        {
            setCookie('analyticsEnabled', 1, 30);
        }
        else
        {
            //Empty the analytics script tag
            setCookie('analyticsEnabled', 0, 30);
            $('#script_analytics').remove();
        }
        $('#cookieBanner').remove();
    });

    $('#btn_cookiesAccept').on('click', () =>
    {
        setCookie('cookiesAccepted', 1, 30);
        setCookie('analyticsEnabled', 1, 30);
        $('#cookieBanner').remove();
    })


});

function setCookie(cname, cvalue, exdays)
{
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname)
{
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) 
    {
        let c = ca[i];
        while (c.charAt(0) == ' ') 
        {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) 
        {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}